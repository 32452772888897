/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 85px 0 0 0;
$body-sticky-padding: 85px 0 0 0;


/* ==========================================================================
   content
   ========================================================================== */
$content-padding: 8vh 0;

/* content > h1
   ========================================================================== */
   $content-h1-font_size: $font-size-base * 2; /* 40 */
   $content-h1-font_size_sm: $font-size-base * 3; /* 60 */
   $content-h1-color--dark: $body-color;

/* content > h2
   ========================================================================== */
   $content-h2-font_size: $font-size-base * 1.5; /* 30 */
   $content-h2-font_size_sm: $font-size-base * 1.8; /* 36 */
   $content-h2-color--dark: $body-color;

/* content > h3
   ========================================================================== */
   $content-h3-font_size: $font-size-base * 1; /* 20 */
   $content-h3-font_size_sm: $font-size-base * 1.2; /* 24 */
   $content-h3-color--dark: $body-color;

/* content > p
   ========================================================================== */
   $content-p-color--dark: $body-color;

/* content > ul & ol
   ========================================================================== */
   $content-list-item-color--dark: $body-color;

/* ==========================================================================
   mini-sab
   ========================================================================== */
$mini-sab-padding: 4vh 0;
$mini-sab-background: $green;

/* ==========================================================================
   footer
   ========================================================================== */
$footer-padding: 0 ;
$footer-background: transparent;


/* ==========================================================================
   card
   ========================================================================== */
$card-background: transparent;
$card-border: none;
$card-base-border-radius: 0;
$card-box_shadow: none;

/* card > card-image
   ========================================================================== */
$card-image-background: transparent;
$card-image-border_radius: 20px;

/* card > card-body
   ========================================================================== */
$card-body-padding: 15px 0 0 0;
$card-body-background: transparent;

/* card > card-description */
$card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);
$card-description-fade-background--dark: linear-gradient(180deg, rgba(#ece1d0,0) 0%, rgba(#ece1d0,1) 100%);
