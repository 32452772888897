html { font-size: initial; }
body { font-size: $font-size-base; }

#TommyBookingSupport {
  .tbs-container-fluid {
    @extend .px-0;
  }

  .tbs-navbar-collapse {
    @extend .px-0;
  }
  
  .tbs-nav {
    li {
      font-size: 14px;
    }
  }

  .tbs-page-overzicht {
    #coupon_code {
      h2 {
        margin-top: 20px;
      }
    }
  }
}


.w3-vue-booking-wrapper .matrix .duration {
  text-transform:unset !important;
}