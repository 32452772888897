.header {
	.header-main {
		background-color: $salmon;

		.column.navbar {
			justify-content: space-between;
			padding: 22px 15px;
			@include media-breakpoint-up(xl) {
				padding: 22px 40px;
			}
		}

		.navbar-toggler {
			z-index: 10;
			position: relative;
			display: block !important;
			height: 41px;
			margin: 0;
			color: $green;
			font-size: 26px;
		}

		.quick-menu {
			position: relative;
			z-index: 20;

			display: none !important;
			@include media-breakpoint-up(xl) {
				display: flex !important;
			}
			margin: 0 auto 0 0;

			li {
				margin: 0 0 0 25px;
				line-height: 41px;

				a {
					color: $green;
					text-decoration: none;

					&:hover {
						color: $red;
					}
				}
			}
		}

		.logo {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
			max-width: 140px;
			margin: 0 auto;
			@include media-breakpoint-up(sm) {
				max-width: 140px;
			}
			@include media-breakpoint-up(xl) {
				max-width: 180px;
			}
		}

		.book-button {
			display: none !important;
			@include media-breakpoint-up(lg) {
				display: flex !important;
			}
			position: relative;
			z-index: 20;
			margin: 0 0 0 auto;
			padding: 0;
			list-style: none;

			a {
				display: block;
				padding: 0.375rem 1.5rem;
				border-radius: 20px;
				box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.15);
				background: $red;
				border: 1px solid $red;
				color: $white;
				font-size: 18px;
				line-height: 1.5;
				text-decoration: none;
				transition: 0.5s;

				&:hover {
					background: $black;
					border-color: $black;
				}
			}
		}

		.language {
			position: relative;
			z-index: 20;
			margin-left: 15px;

			.dropdown-toggle {
				display: flex;
				justify-content: space-around;
				width: 80px;
				height: 41px;
				border: 1px solid $green;
				border-radius: 22px;
				color: $green;

				&:after {
					content: "\f107";
					font-family: "Font Awesome 5 Pro";
					font-weight: 300;
					line-height: 1;
					border: none;
					font-size: 24px;
				}

				img {
					display: block;
					width: 26px;
					height: 26px;
					border-radius: 100%;
				}
			}

			.dropdown-menu {
				min-width: 80px;
				position: absolute;
				background: $salmon;
				border-color: $green;
				padding: 0;
				border-radius: 22px;
				overflow: hidden;

				img {
					display: block;
					width: 26px;
					height: 26px;
					border-radius: 100%;
				}
			}
		}

		.column.navbar {
			.navbar-collapse {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				align-items: flex-start;

				padding: 25vh 30px;
				background-color: rgba($white, .85);
				overflow: scroll;

				@include media-breakpoint-up(xl) {
					display: none !important;
				}

				.navbar-nav {
					@extend .d-block;
					margin: 0 auto;

					.nav-item {
						font-size: $font-size-base * 1.125;
						font-weight: $font-weight-bold;
						text-align: center;

						.nav-link {
							color: $green;

							&:hover {
								color: $red;
							}
						}
					}

					.dropdown {
						.dropdown-menu {
							@extend .position-relative;

							background-color: transparent;
							border: 2px solid $green;

							.nav-item {
								font-weight: $font-weight-base;

								.nav-link {
									padding: 5px 8px;
								}
							}
						}
					}
				}

				&.show {
					display: flex !important;
				}
			}
		}

	}

	/*  Header - Sticky
		========================================================================== */
	&.sticky {
		.header-main {
			.logo {
				@include media-breakpoint-up(xl) {
					max-width: 100px;
				}
			}
		}
	}
}

.w3-vue-booking-wrapper .matrix .duration {
	text-transform: unset !important;
}

.modal-dialog {
	width: 540px;
	max-width: calc(100vw - 1rem);
}
