/*  home-blocks
    ========================================================================== */
    &.home .home-blocks { padding: 8vh 0;}
    .home-blocks {
        padding: 8vh 0;
        @include media-breakpoint-up(lg) {
            .grid { 
                margin: 0 -30px -60px -30px;
                .item {
                    margin-bottom: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
        .card {
            .card-caption {
                flex: none;
                .card-title {
                    @include media-breakpoint-up(xl) {
                        font-size: $font-size-base * 1.8;
                    }                  
                }
                .card-description {
                    min-height: 0;
                    max-height: none;
                    &:after { display: none !important;}
                }
            }
        }
    }
    .home-blocks {
        .page_block.contentblock.grid {
            .item {
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
            }
        }
    }

/*  highlights
    ========================================================================== */
    .highlights {
        position: relative;
        margin-top: 134px;
        padding: 8vh 0;
        background: $green;
        &:before {
            content: "";
            z-index: 50;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            width: 100%;
            height: 85px;
            margin-bottom: -1px; /* pixel fix */
            background: url('/images/highlights-before.svg');
            background-size: cover;
        }
        .card {
            .card-image {
                width: 70px;
                height: 70px;
                margin: 0 auto;
                border-radius: 100%;
                background: transparent;
                color: $primary;
                font-size: 36px;
                line-height: 70px;
            }
            .card-body {
                .card-caption {
                    text-align: center;
                    .card-title {
                        color: $white;
                        font-size: 24px;
                        text-align: center;
                    }
                    .card-description {
                        color: $yellow;
                        font-size: 16px;
                        min-height: auto;
                        max-height: none;
                        text-align: center;
                        &:after { display: none !important;}
                    }
                }
                .card-buttons {
                    display: none;
                }
            }
        }
    }
    .highlights {
        .page_block.contentblock.grid {
            margin-bottom: -80px;
            .item {
                margin-bottom: 80px;
                @include make-col(6);
                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(3);
                }
            }
        }
    }

/*  home-double
    ========================================================================== */
    &.home .home-double { padding: 0 0 8vh 0;}
    .home-double {
        background: $salmon;
        text-align: center;
        .container-two-columns {
            .column.one {
                @include media-breakpoint-up(md) {
                    @include make-col(5);
                    text-align: right;
                }
            }
            .column.two {
                @include media-breakpoint-up(md) {
                    @include make-col(7);
                    text-align: left;
                }
            }
        }
    }

/*  home-double-2
    ========================================================================== */
    .home-double-2 {
        padding: 8vh 0;
        text-align: center;
        .container-two-columns {
            .column.one {
                @include media-breakpoint-up(md) {
                    text-align: right;
                }
            }
            .column.two {
                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }
    }

/*  accommodation-blocks
    ========================================================================== */
    .accommodation-blocks { 
        padding: 8vh 0 0 0;
        .card {
            .card-body {
                .card-caption {
                    flex: none;
                    .card-title {
                        font-size: $font-size-base * 1; /* 20px */
                        @include media-breakpoint-up(xl) {
                            font-size: $font-size-base * 1.2; /* 24px */
                        }                  
                    }
                    .card-description {
                        min-height: auto;
                        max-height: none;
                        &:after { display: none !important;}
                    }
                }
                .card-buttons {
                    .card-btn {
                        background: transparent;
                        color: $primary;
                        &:hover {
                            border-color: $primary;
                            background: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }