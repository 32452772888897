/*  content-blocks
    ========================================================================== */
    .content-blocks {
        position: relative;
        padding: 8vh 0;
        background: $salmon;
        &:before {
            content: "";
            z-index: 50;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 28px;
            margin-top: -1px; /* pixel fix */
            background: url('/images/content-blocks-before.svg');
            background-size: cover;
        }
        .card {
            text-decoration: none;
            overflow: visible;
            .card-caption {
                .card-title {
                    font-size: $font-size-base * 1.2;
                }
                .card-description {
                    min-height: 0;
                    max-height: none;
                    &:after { display: none !important;}
                }
            }
            &:hover .card-title { color: $primary;}
        }
    }

/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }

/*  spacer
    ========================================================================== */
    .spacer {
        .container-default {
            position: relative;
            &:before {
                content: "";
                z-index: 50;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 94px;
                margin-top: -1px; /* pixel fix */
                background: url('/images/spacer-before.svg') no-repeat top center;
                background-size: cover;
            }
            &:after {
                content: "";
                z-index: 50;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 38px;
                margin-bottom: -1px; /* pixel fix */
                background: url('/images/spacer-after.svg') no-repeat top center;
                background-size: cover;
            }
            .owl-carousel {
                .item {
                    .owl-caption { 
                        max-width: 520px;
                        margin: 0 auto;
                        text-align: center;
                        .owl-title {
                            font-size: 40px;
                            line-height: 1;
                            text-shadow: none;
                            @include media-breakpoint-up(xl) {
                                font-size: 60px;
                            }
                        }
                    }
                }
            }
        }
    }