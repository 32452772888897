/*  bootstrap > colors
    ========================================================================== */
    $red:           #9b192c;
    $yellow:        #e2bd81;
    $yellow-dark:   #da8d17;
    $green:         #415952;
    $salmon:        #ece1d0;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:       $red;
    $secondary:     $yellow;
    $tertiary:		$green;
    
    $dark:          $salmon;

    $theme-colors: (
    	"tertiary":	$tertiary,
    );

/*  bootstrap > body
    ========================================================================== */
    $link-color: $primary;
   
/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Ropa+Sans:ital@0;1&display=swap');
    @font-face {
        font-family: 'Titania';
        font-style: normal;
        font-weight: 400;
        src: local('Titania'), url('/images/Titania-Regular.woff') format('woff');
    }

    $font-family-titania: 'Titania', sans-serif;
    $font-family-ropa-sans: 'Ropa Sans', sans-serif;
    $font-family-base: $font-family-ropa-sans;
   
/*  bootstrap > typography
    ========================================================================== */   
    $font-size-base:                1.25rem; /* 20px */

    $headings-font-family:          $font-family-titania;
    $headings-font-weight:          400;

    /*
    Kleuren
    Rood: #9b192c (o.a. buttons)
    Zalm: #ece1d0 (o.a. header en footer)
    Groen: #415952
    Geel: e2bd81
    Geel (donker): #da8d17 (komt niet terug enkel in logo)
    
    Tekst
    Ropa Sans base font-size 20
    Eyecatcher: Titania 60
    h1 60 > 40
    h2 36 > 30
    h3 24 > 20 
    h4 18
    h5 16
    h6 14
    Button: 18, round 22, height 40
    */

/*  owl-carousel
    ========================================================================== */
    $carousel-nav-button-width: 50px;
    $carousel-nav-button-font_size: 1.5rem;