.eyecatcher {
	position: relative;
	&:after {
		content: "";
		z-index: 50;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 38px;
		margin-bottom: -1px; /* pixel fix */
		background-image: url('/images/eyecatcher-after-green.svg');
		@include media-breakpoint-up(xl) {
			background-image: url('/images/eyecatcher-after.svg');
		}
		background-size: cover;
	}
	.owl-carousel {
		.item {
			.owl-caption { 
				max-width: 520px;
				margin: 0 auto;
				text-align: center;
				.owl-title {
					font-size: 40px;
					line-height: 1;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
						font-size: 60px;
					}
				}
			}
		}
	}
	&:not(.large) {
		@include media-breakpoint-up(xl) {
			min-height: 350px;
		}
		.item {
			@include media-breakpoint-up(xl) {
				min-height: 350px;
			}
		}
	}
}
.eyecatcher.large {
	@include media-breakpoint-down(sm) {
		min-height: $carousel-min_height;
	}
}