/*  footer
    ========================================================================== */
    .footer {
        margin-top: 8vh;
        color: $green;
        font-size: 16px;
        h4, h5 {
            color: $green;
            font-size: 18px;
        }    
        a {
            color: $green;
            &:hover { color: $primary;}
        }    

        /* footer-top */
        .footer-top {
            position: relative;
            height: 548px;
            background: url('/images/map.svg') center right #91bfff;
            background-size: cover;
            @include media-breakpoint-down(xs) {
                background-position: 75% 0;
            }   
            &:before {
                content: "";
                z-index: 50;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 62px;
                margin-top: -1px; /* pixel fix */
                background: url('/images/footer-top-before.svg') top center;
                background-size: cover;
            }
        }

        /* footer-middle */
        .footer-middle {
            position: relative;
            padding: 4vh 0;
            background: $salmon;
            &:before {
                content: "";
                z-index: 50;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 100%;
                width: 100%;
                height: 38px;
                margin-bottom: -1px; /* pixel fix */
                background: url('/images/footer-middle-before.svg') top center;
                background-size: cover;
            }
            .logo {
                z-index: 100;
                position: relative;
                margin-bottom: 30px;
                margin-top: -150px;
                @include media-breakpoint-up(lg) {
                    margin-top: -175px;
                    max-width: 50%;
                }   
                img {
                    width: 175px;
                    height: 175px;
                    margin: 0 auto;
                    @include media-breakpoint-up(lg) {
                        width: 237px;
                        height: 237px;
                    }  
                }
            }
            .container-four-columns {
                .container-holder {
                    justify-content: center;
                    text-align: center;
                    @include media-breakpoint-up(lg) {
                        text-align: left;
                    }
                }
                .column.one {
                    @include make-col(12);
                    @include media-breakpoint-up(lg) {
                        @include make-col(6);
                    }
                }
                .column.two,
                .column.three,
                .column.four {
                    @include make-col(6);
                    @include media-breakpoint-up(sm) {
                        @include make-col(4);
                    }
                    @include media-breakpoint-up(lg) {
                        @include make-col(2);
                    }

                }
            }
            .column.one {
                .footer_list { display: none;}
            }
            .social {
                margin: 30px 0;
                .list {
                    justify-content: center;
                    .list-item {
                        margin: 2px;
                        .link {
                            display: block;
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            background: $primary;
                            color: $white;
                            font-size: 24px;
                            line-height: 60px;
                            text-align: center;
                            &:hover {
                                background: $green;
                            }
                        }
                    }
                }
            }
        }
    
        /* footer-bottom */
        .footer-bottom {
            position: relative;
            padding: 30px 0;
            background: $white;
            text-align: center;
            .list {
                justify-content: center;
                .list-item {
                    margin: 0 10px;
                    .link {
                        color: $green;
                        &:hover { color: $primary;}
                    }
                }
            }
        }

        .recron-image img { 
            margin-top:33px;   
        }

    }
