.page_block .card {
    overflow: visible;
    @include media-breakpoint-down(sm) {
        .card-body {
            .card-caption {
                .card-title,
                .card-subtitle,
                .card-description {
                    text-align: center;
                }
            }
            .card-buttons { 
                text-align: center;
            }
        }
    }
}