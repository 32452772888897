.mini-sab {
    position: relative;
    z-index: 998;
    box-shadow: 10px 10px 15px rgba($color: #000000, $alpha: 0.3);
    @include media-breakpoint-up(xl) {
        height: 0;
        padding: 0;
        box-shadow: none;
    }
    .container {
        position: relative;
    }

    .container-holder {
        color: $white;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        @include media-breakpoint-up(xl) {
            position: absolute;
            z-index: 100;
            left: 15px;
            right: 15px;
            bottom: 100%;
            margin: 0 auto 70px auto;
            padding: 1rem 0px;
            max-width: 1040px;
            background: $green;
            box-shadow: 10px 10px 15px rgba($color: #000000, $alpha: 0.3);
            border-radius: 60px;
        }
    }
}