&.accommodation_category_detail,
&.accommodation_detail {
	.accommodation_detail {
		.info {
			&.full-width {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.tommy-info {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}

&.accommodation_search_book {
	.list-item-image-slider {
		.swiper-button-prev,
		.swiper-button-next {
			font-size: 16px;
		}
	}
	.list.list-icons .list-item .icon {
		position: static !important;
		font-size: 16px;
	}
}

&.accommodation_book {
	padding: 0;

	.container-fluid {
		padding: 0 15px;
	} 
}